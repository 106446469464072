import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Side menu service. */
@Injectable({
	providedIn: 'root',
})
export class SideMenuService {

	/** Whether menu is opened. */
	public readonly isOpen$ = new BehaviorSubject(false);

	/** Menu portal content. */
	public readonly menuPortal$ = new BehaviorSubject<Portal<unknown> | null>(null);

	/** Toggle the menu state. */
	public toggle(): void {
		this.isOpen$.next(!this.isOpen$.value);
	}
}
