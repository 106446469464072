import { z } from 'zod';

/** Pagination dto schema. */
export const paginationDtoSchema = z.object({
	current_page: z.number(),
	last_page: z.number(),
	per_page: z.number(),
	total: z.number(),
	links: z.object({
		first: z.string(),
		last: z.string(),
		prev: z.string().nullable(),
		next: z.string().nullable(),
	}),
});

/** Pagination query param dto. */
export const paginationQueryParamDtoSchema = z.object({
	page: z.number().optional(),
	per_page: z.number().optional(),
});

/** Pagination dto. */
export type PaginationDto = Readonly<z.infer<typeof paginationDtoSchema>>;

/** Pagination query param dto. */
export type PaginationQueryParamDto = Readonly<z.infer<typeof paginationQueryParamDtoSchema>>;
