import { ZodType, z } from 'zod';

import { PaginationDto, paginationDtoSchema } from './pagination.dto';

/** List response dto. */
export type ListResponseDto<T> = {

	/** Array of item requested. */
	readonly data: readonly T[];

	/** Metadata. */
	readonly meta: {

		/** Pagination. */
		readonly pagination: PaginationDto;
	};
};

/**
 * Create list response dto schema parser function.
 * @param itemSchema Item schema.
 **/
export function createListResponseDtoSchema<T extends z.ZodTypeAny>(itemSchema: T): ZodType<ListResponseDto<z.infer<T>>> {
	return z.object({
		data: z.array(itemSchema),
		meta: z.object({
			pagination: paginationDtoSchema,
		}),
	});
}
