import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Loading service in global scope. */
@Injectable({
	providedIn: 'root',
})
export class LoadingService {

	/** Whether app is loading or not. */
	public readonly isLoading$ = new BehaviorSubject(false);
}
